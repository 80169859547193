// project import
import ApplicationMenu from './applications';

// types
import { NavItemType } from 'types/menu';
import { decrypt } from 'utils/commonFunctions';
import axios from 'axios';

// import DashboardDetail from "./dashboardDetail";
interface Branch {
  name: string;
  code: string;
}
// ==============================|| MENU ITEMS ||============================== //
const iomURL = process.env.REACT_APP_IOM_URL;
const menuItems: { items: NavItemType[] } = {
  items: []
};
let branches: Branch[] = [];
const getBranches = async () => {
  const userData: any = localStorage.getItem('userData');
  const navRights: any = localStorage.getItem('userRights');
  let data: any = null;
  if (userData && navRights) {
    var obj = JSON.parse(decrypt(userData));
    let formData = new FormData();
    formData.append('rights', navRights);
    formData.append('companyUserId', obj.COMPANYUSERID);
    formData.append('userId', obj.USERID);
    formData.append('loggedInTime', obj.LOGGEDINTIME);
    const response = await axios.post(`${iomURL}/getBranches`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    data = response.data;
    branches = response.data.data;
  }

  menuItems.items = [ApplicationMenu(branches)];
  if (data?.STATUS === true) {
    // localStorage.setItem('getRights',(data?.DATA));
  }
};
getBranches();
export default menuItems;
