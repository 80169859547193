import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import { v4 as uuidv4 } from 'uuid';
// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { AuthProps, JWTContextType } from 'types/auth';
import { createObject, createToken, encrypt, notifyError, decrypt } from 'utils/commonFunctions';
import jsSHA from 'jssha';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { menuInstance } from 'store/reducers/menu';
import { useDispatch } from 'react-redux';

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const chance = new Chance();

  // constant
  const initialState: AuthProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
  };

  const setSession = () => {
    // sessionStorage.removeItem('userData');
    // sessionStorage.removeItem('instanceId');
    dispatchInstance(menuInstance([]));
    localStorage.clear();

    delete axios.defaults.headers.common.Authorization;
  };

  // const baseURL = process.env.REACT_APP_AUTH_URL;
  const iomURL = process.env.REACT_APP_IOM_URL;
  const [state, dispatch] = useReducer(authReducer, initialState);
  const dispatchInstance = useDispatch();
  const uuid = uuidv4();
  const navigate = useNavigate();

  const login = async (email: any, password: any) => {
    try {
      var loginParam = new FormData();
      // Calculate the first hash
      const shaObj = new jsSHA('SHA-512', 'TEXT');
      shaObj.update(password);
      const hash = shaObj.getHash('HEX');
      // Calculate the second hash by concatenating the first hash and UUID
      const shaObj1 = new jsSHA('SHA-512', 'TEXT');
      shaObj1.update(hash.toUpperCase() + uuid);
      const hash1 = shaObj1.getHash('HEX');
      //  Appending form data
      // const id: any = sessionStorage.getItem('instanceId');
      const id: any = localStorage.getItem('instanceId');
      var instanceId;
      if (id) {
        instanceId = decrypt(id);
      }
      loginParam.append('instanceId', instanceId ? instanceId : '');
      loginParam.append('username', email);
      loginParam.append('password', hash1);
      loginParam.append('rUid', uuid);
      let object = createObject(loginParam);
      let token = createToken(object);
      let encryptedToken = encrypt(token);
      if (encryptedToken && id) {
        let newToken = new FormData();
        newToken.append('encrToken', encryptedToken);
        const response = await axios.post(`${iomURL}/login`, newToken, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;

        if (data?.STATUS === true) {
          localStorage.setItem('userData', encrypt(JSON.stringify(data?.DATA)));

          // console.log('getItemgetItemgetItemgetItem',localStorage.getItem(('userData')));

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              isInitialized: true,
              user: data?.DATA
            }
          });
          await userRights();
        } else {
          notifyError(<FormattedMessage id={data?.MESSAGE} />);
        }
      }
    } catch (error) {
      notifyError(<FormattedMessage id="errorMessage" />);
      return null;
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        // const serviceToken = window.sessionStorage.getItem('userData');
        const serviceToken = window.localStorage.getItem('userData');
        if (serviceToken) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true
            }
          });
          await userRights();
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
        navigate('/login');
      }
    };

    init();
  }, []);

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response?.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    if (users) {
      window.localStorage.setItem('users', JSON.stringify(users));
    }
  };
  const userRights = async () => {
    const id: any = localStorage.getItem('instanceId');
    const userData: any = localStorage.getItem('userData');
    var obj = JSON.parse(decrypt(userData));

    let formData = new FormData();
    formData.append('instanceId', decrypt(id));
    formData.append('companyUserId', obj.COMPANYUSERID);
    formData.append('userId', obj.USERID);
    formData.append('loggedInTime', obj.LOGGEDINTIME);
    let object = createObject(formData);
    let token = createToken(object);
    let encryptedToken = encrypt(token);
    if (encryptedToken && id) {
      let encrData = new FormData();
      encrData.append('encrToken', encryptedToken);
      const response = await axios.post(`${iomURL}/userRights`, encrData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const data = response.data;
      if (data?.STATUS === true) {
        localStorage.setItem('userRights', data?.DATA);
        await getUserNavbranches();
      }
    }
  };
  const getUserNavbranches = async () => {
    const userData: any = localStorage.getItem('userData');
    var obj = JSON.parse(decrypt(userData));
    const navRights: any = localStorage.getItem('userRights');
    // console.log(rights)
    let formData = new FormData();
    formData.append('rights', navRights);
    formData.append('companyUserId', obj.COMPANYUSERID);
    formData.append('userId', obj.USERID);
    formData.append('loggedInTime', obj.LOGGEDINTIME);
    const response = await axios.post(`${iomURL}/getBranches`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    const data = response.data;
    dispatchInstance(menuInstance(data));
    if (data?.STATUS === true) {
      // localStorage.setItem('getRights',(data?.DATA));
    }
  };
  const logout = async () => {
    try {
      const logoutResponse = await axios.post(
        `${iomURL}/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      const data = logoutResponse.data;
      if (data?.status === true) {
        setSession();
        dispatch({ type: LOGOUT });
      } else {
        notifyError(<FormattedMessage id="errorMessage" />);
      }
    } catch (error) {
      notifyError(<FormattedMessage id="errorMessage" />);
      return null;
    }
  };

  const resetPassword = async (email: string) => {};
  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
