import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken';
import CryptoJS from 'crypto-js';

export const notifySuccess = (message: any) =>
  toast(<p style={{ fontSize: 16 }}>{message}</p>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    // @ts-ignore
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'success'
  });

export const notifyError = (message: any) =>
  toast(<p style={{ fontSize: 16 }}>{message}</p>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    // @ts-ignore
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'error'
  });

export const createObject = (data: FormData): Record<string, string> => {
  const object: Record<string, string> = {};

  if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fn, scope) {
      for (let i = 0, len = this.length; i < len; ++i) {
        fn.call(scope, this[i], i, this);
      }
    };
  }
  data.forEach(function (value, key) {
    // @ts-ignore
    object[key] = value;
  });
  return object;
};

export const formatPrivateKey = (privateKey: string): string => {
  const begin = '-----BEGIN RSA PRIVATE KEY-----\n';
  const end = '\n-----END RSA PRIVATE KEY-----';
  // Use a regular expression to insert newlines every 64 characters
  const formattedKey = privateKey.replace(/(.{64})/g, '$1\n');

  return `${begin}${formattedKey}${end}`;
};

export const createToken = (data: any): any => {
  const privateKey: any = process.env.REACT_APP_SECRET_KEY;
  const paramsOption: jwt.SignOptions = {
    algorithm: 'RS256',
    expiresIn: '2 days'
  };
  const formattedPrivateKey = formatPrivateKey(privateKey);
  const token: any = jwt.sign(data, formattedPrivateKey, paramsOption);
  return token;
};

export const encrypt = (data: string): any => {
  const ivKey: any = process.env.REACT_APP_ENCRYPTION_KEY;
  if (ivKey) {  
    const binaryEncryptionKey = CryptoJS.enc.Base64.parse(ivKey);
    const cipher = CryptoJS.AES.encrypt(data, binaryEncryptionKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return cipher.toString();
  }
};

export const decrypt = (encryptedData: string): any => {
  const ivKey: any = process.env.REACT_APP_ENCRYPTION_KEY;
  if (ivKey) {
    const binaryEncryptionKey = CryptoJS.enc.Base64.parse(ivKey);
    const bytes = CryptoJS.AES.decrypt(encryptedData, binaryEncryptionKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return bytes.toString(CryptoJS.enc.Utf8);
  }
};
